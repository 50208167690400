<div
  class="relative mx-auto bg-white shadow-2xl rounded-lg px-6 py-14 md:p-14 max-w-lg flex flex-col items-center">
  <p class="font-medium text-gray-500 text-md mt-3">
    {{ 'comeBack' | translate: { firstName: displayName() } }}
  </p>

  <a appHoverGradient
     class="big-btn py-4 text-md"
     routerLink="/account">
    {{ 'auth' | translate }}
  </a>

  <button (click)="signOut()"
          (keyup.enter)="emulateClick($event)"
          appHoverGradient
          class="big-btn py-4 text-md">
    {{ 'auth.signOut' | translate }}
  </button>
</div>
