import { FirebaseOptions } from '@angular/fire/app';
import { bootstrapApplication } from '@angular/platform-browser';
import { FirebaseApp as CapFbApp } from '@capacitor-firebase/app';
import { Capacitor } from '@capacitor/core';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (Capacitor.isNativePlatform()) {
  CapFbApp.getOptions().then(async options => {
    const opts: FirebaseOptions = {
      appId: options.applicationId,
      projectId: options.projectId,
      apiKey: options.apiKey,
      storageBucket: options.storageBucket,
      databaseURL: options.databaseUrl,
      messagingSenderId: options.gcmSenderId,
      authDomain: environment.firebase.authDomain
    }
    bootstrapApplication(AppComponent, appConfig(opts))
      .catch(err => console.error('bootstrapApplication ERROR', err));
  });
} else {
  bootstrapApplication(AppComponent, appConfig(environment.firebase))
    .catch(err => console.error(err));
}
