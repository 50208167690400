<!--Header -->
<header class="relative bg-cover bg-center">
  @if (headerService.type() !== 'minimal') {
    <div class="overflow-hidden w-full h-full absolute top-0 z-[-1]">
      @if (!siteService.center() && globalService.landing().headerDisplayed === 'video') {
        <app-video fill="cover" [video]="{video: globalService.landing().headerVideo, background: true}"/>
      } @else {
        @for (image of images(); track image; let i = $index) {
          <img [alt]="image.alt"
               [ngSrc]="image.media"
               sizes="100vw"
               fill=""
               priority
               [class.animate-zoom-in]="animatedImageIndex.includes(i)"
               [ngClass]="i === animatedImageIndex[1] ? 'opacity-100' : 'opacity-0'"
               class="absolute object-cover top-0 left-0 w-full h-full transition-opacity duration-1000">
        }
      }
    </div>
  }
  <!--Navigation-->
  <div
    [ngClass]="{
    'pb-20 pt-3' : headerService.type() !== 'minimal'
     }">
    <nav class="layout border-gray-200 flex items-center justify-center md:justify-between py-6">
      <!--Logo-->
      <a class="flex items-center justify-center space-x-3 rtl:space-x-reverse" routerLink="/">
        <!--Needing to use @if instead of [ngSrc]= _ ? _ : _  because of an NgSrc UpdateImage reliance on window SSR bug -->
        @if (giftMode()) {
          <img alt="{{'les100ciels' | translate}}" class="h-16 lg:h-16 w-auto"
               ngSrc="assets/images/lescentsciels-logo.svg"
               priority
               width="300"
               height="76">
        } @else {
          <img alt="{{'les100ciels' | translate}}" class="h-16 lg:h-16 lg:w-auto"
               ngSrc="assets/images/lescentsciels-logo.svg"
               priority
               width="300"
               height="76">
        }

        <span class="sr-only">Homepage</span>
      </a>
      <!--Search block-->
      @if (headerService.search()) {
        <button class="relative group ml-auto flex flex-col items-center"
                aria-labelledby="search"
                (click)="drawerService.open({component: SearchComponent, inputs: undefined, style: 'transparent'})"
                [ngClass]="textColorClasses()">
          <svg class="h-10 w-10 block m-auto object-cover p-0.5"
               [ngClass]="svgColorClasses()"
               viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.85">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M14.5776 14.5419C15.5805 13.53 16.2 12.1373 16.2 10.6C16.2 7.50721 13.6928 5 10.6 5C7.50721 5 5 7.50721 5 10.6C5 13.6928 7.50721 16.2 10.6 16.2C12.1555 16.2 13.5628 15.5658 14.5776 14.5419ZM14.5776 14.5419L19 19"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
          </svg>
          <span class="text-xs -mt-1">{{ 'search' | translate }}</span>
        </button>
      }
      <!--Navbar icons -->
      <div class="bg-white fixed left-0 right-0 bottom-0 flex items-center z-30 py-2 border-t border-gray-200 divide-x
              md:bg-transparent md:static md:flex md:py-0 md:border-0 md:border-transparent md:divide-x-0">
        <!--Go to center-->
        <div appAutoClose="always" appHover class="basis-0 md:basis-auto grow md:relative">
          <button
            [ngClass]="textColorClasses()"
            class="group mx-auto flex flex-col items-center md:py-2 md:px-3 hover:bg-white hover:bg-opacity-20 md:rounded-lg">
            <svg [ngClass]="{'lg:h-10 lg:w-10': !headerService.search()}"
                 class="h-8 w-8 block m-auto object-cover p-0.5"
                 viewBox="0 0 60 60">
              <path [ngClass]="svgColorClasses()" class="fill-none"
                    d="m52.61,24.57c0,10.71-5.63,18.77-22.53,33.79M7.55,24.57c0,10.71,5.63,18.77,22.53,33.79M7.55,24.57C7.55,12.12,17.64,2.04,30.08,2.04s22.53,10.09,22.53,22.53m-13.14,0c0,5.18-4.2,9.39-9.39,9.39s-9.39-4.2-9.39-9.39,4.2-9.39,9.39-9.39,9.39,4.2,9.39,9.39Z"
                    style="stroke-width: 2.5px"/>
            </svg>
            <span [ngClass]="{'lg:hidden': !headerService.search()}"
                  class="text-xs mt-1">
              {{ 'cities' | translate }}</span>
            <span [class.lg:block]="!headerService.search()"
                  class="hidden pr-4">{{ '100cielsCenters' | translate }}</span>
          </button>
          <ul class="absolute grid max-h-[80vh] overflow-y-auto gap-2 z-10 rounded-md bg-white py-4 px-5 ring-1
            text-base shadow-lg ring-black ring-opacity-5 bottom-14 left-4 right-4 m-auto focus:outline-none
            md:min-w-max md:-left-40 md:grid-cols-2 md:max-w-fit md:top-16 md:bottom-auto md:px-7 sm:text-sm">
            @if (headerService.type() !== 'minimal') {
              <svg class="fill-white h-3 mx-auto hidden lg:block absolute right-0 left-0 -top-3"
                   xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0 0 381.95 150.4">
                <path class="fill-white" d="M0,150.4C51.71,98.69,102.14,48.26,121.71,28.69C126.33,24.08,151.32,0,190.98,0
	c25.07,0,50.14,9.56,69.27,28.69c20.7,20.7,71.87,71.87,121.71,121.71"/>
              </svg>
            }
            @for (center of centerService.centers(); track center.id) {
              <li class="text-100ciels-500 grow rounded select-none cursor-pointer font-normal
                hover:text-100ciels-300"
                  [ngClass]="{
                  'font-semibold': selectionService.center()?.id === center.id,
                  '!bg-gray-100 hover:!bg-gray-100 cursor-default': !center.url
                  }"
                  [attr.aria-selected]="selectionService.center()?.id === center.id">
                <a class="text-lg block truncate px-4 py-2 w-full"
                   [href]="center.url"
                   (click)="navigationService.goTo(center)"
                   (keyup.enter)="emulateClick($event)">
                  <p class="w-full text-sm text-left leading-6 font-semibold">{{ center.name }}</p>
                  <p class="w-full text-xs text-left leading-5 truncate">{{ center.city }}</p>
                </a>
              </li>
            }
          </ul>
        </div>
        <!--Go to products -->
        @if (siteService.center()) {
          <!--Go to shop button -->
          <a routerLink="/offers"
             [attr.aria-label]="'shop' | translate"
             class="mt-1 basis-0 md:basis-auto grow relative group flex flex-col items-center md:py-1 md:px-1 hover:bg-white hover:bg-opacity-20 md:rounded-lg"
             [ngClass]="textColorClasses()">
            <svg viewBox="0 0 60 60" class="h-8 w-8 block m-auto object-cover p-0.5"
                 [ngClass]="{'lg:h-10 lg:w-10': !headerService.search()}">
              <defs>
                <style>
                  .cls-1 {
                    fill: none;
                    stroke-miterlimit: 10;
                    stroke-width: 1.6px;
                  }
                </style>
              </defs>
              <g>
                <g>
                  <path class="cls-1" [ngClass]="svgColorClasses()"
                        d="M11.9,9v-2c0-2.9,2.3-5.2,5.2-5.2h25.9c2.9,0,5.2,2.3,5.2,5.2v2"/>
                  <path class="cls-1" [ngClass]="svgColorClasses()"
                        d="M48.1,28.6v24.4c0,2.9-2.3,5.2-5.2,5.2h-25.9c-2.9,0-5.2-2.3-5.2-5.2v-24.6"/>
                  <polyline class="cls-1" [ngClass]="svgColorClasses()" points="42.7 48.2 17.2 48.2 17.2 32.9"/>
                </g>
                <line class="cls-1" [ngClass]="svgColorClasses()" x1="26.3" y1="53.4" x2="33.7" y2="53.4"/>
              </g>
              <path class="cls-1" [ngClass]="svgColorClasses()"
                    d="M38.2,17.8v2.5c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2v-2.5l-6.2-8.6H11.8l-6.2,8.6v2.5c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2v-2.5"/>
              <path class="cls-1" [ngClass]="svgColorClasses()"
                    d="M21.8,20.2h0c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2h0"/>
              <line class="cls-1" [ngClass]="svgColorClasses()" x1="5.5" y1="17.8" x2="54.5" y2="17.8"/>
              <line class="cls-1" [ngClass]="svgColorClasses()" x1="21.8" y1="17.8" x2="24" y2="9.2"/>
              <line class="cls-1" [ngClass]="svgColorClasses()" x1="38.2" y1="17.8" x2="36" y2="9.2"/>
            </svg>
            <span class="text-xs"
                  [ngClass]="{'lg:text-base lg:pr-4': !headerService.search()}">
              {{ 'shop' | translate }}
            </span>
          </a>
          <!--Gift button -->
          <div appHover appAutoClose="always" class="basis-0 md:basis-auto grow md:relative">
            <button
              class="group mx-auto flex flex-col items-center md:py-1 md:px-3 hover:bg-white hover:bg-opacity-20 md:rounded-lg"
              [ngClass]="textColorClasses()">
              <svg viewBox="0 0 60 60" class="h-9 w-9 block m-auto object-cover"
                   [ngClass]="{'lg:h-12 lg:w-12': !headerService.search()}">
                <defs>
                  <style>.cls-1 {
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.76px;
                  }</style>
                </defs>
                <path class="cls-1 fill-none stroke-2 p-0.5"
                      [ngClass]="svgColorClasses()"
                      d="m24.72,30.69h-12.31c-1.46,0-2.64-1.18-2.64-2.64v-3.52c0-1.46,1.18-2.64,2.64-2.64h35.18c1.46,0,2.64,1.18,2.64,2.64v3.52c0,1.46-1.18,2.64-2.64,2.64h-12.31m0-5.28v10.55l-5.28-1.76-5.28,1.76v-14.07m22.87,13.19v12.31c0,1.46-1.18,2.64-2.64,2.64H15.05c-1.46,0-2.64-1.18-2.64-2.64v-16.71m24.62-11.05c7.01-3.93,6.07-2.98,2.75-8.55-2.18-3.65-5.76-.1-8.9,4.62m-10.55,4.07c-3.99-2.06-2.95-2.04-.24-6.17,2.21-3.36,5.94.39,9.06,4.86"/>
              </svg>
              <span class="text-xs"
                    [ngClass]="{'lg:hidden': !headerService.search()}">
                {{ 'gift' | translate }}</span>
              <span class="hidden pr-4"
                    [class.lg:block]="!headerService.search()">{{ 'giftVouchers' | translate }}</span>
            </button>
            <ul class="absolute md:min-w-max w-[90vw] md:w-[unset] left-4 bottom-12
             md:-left-8 md:top-16 md:right-auto md:bottom-auto
            z-10 rounded-md bg-white py-4 text-base text-100ciels-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
            grid grid-cols-1">
              @if (headerService.type() !== 'minimal') {
                <svg class="fill-white h-3 mx-auto hidden lg:block absolute right-0 left-0 -top-3"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 381.95 150.4">
                  <path class="fill-white" d="M0,150.4C51.71,98.69,102.14,48.26,121.71,28.69C126.33,24.08,151.32,0,190.98,0
	c25.07,0,50.14,9.56,69.27,28.69c20.7,20.7,71.87,71.87,121.71,121.71"/>
                </svg>
              }
              <li class="grow hover:text-100ciels-300 px-8 py-2 rounded select-none cursor-pointer font-normal">
                <a routerLink="/offers" [queryParams]="{gift: true}" class="flex gap-4 items-center">
                  <div class="relative h-8 w-8">
                    <img alt="" fill="" class="object-fill" ngSrc="assets/icons/yellow/gift.svg">
                  </div>
                  <span class="font-semibold text-end">{{ 'buyGift' | translate }}</span>
                </a>
              </li>
              @if (showClaimGift) {
                <li class="border-y border-gray-200 grow hover:text-100ciels-300 px-8 py-2 rounded select-none
                cursor-pointer font-normal">
                  <button aria-labelledby="claimGift"
                          (click)="openClaimGiftComponent()"
                          class="flex gap-4 items-center">
                    <div class="relative h-8 w-8">
                      <img alt="" fill="" class="object-fill" ngSrc="assets/icons/gray/use_gift.svg">
                    </div>
                    <span id="claimGift" class="font-semibold text-end">{{ 'claimGift' | translate }}</span>
                  </button>
                </li>
              }
              <li class="grow hover:text-100ciels-300 px-8 py-2 rounded select-none cursor-pointer font-normal">
                <a routerLink="/vouchers" class="flex gap-4 items-center">
                  <div class="relative h-8 w-8">
                    <img alt="" fill="" class="object-fill" ngSrc="assets/icons/gray/cheque.svg">
                  </div>
                  <span class="font-semibold text-end">{{ 'buyVoucher' | translate }}</span>
                </a>
              </li>
            </ul>
          </div>
        } @else {
          <!--Display shops -->
          <div appHover appAutoClose="always" class="basis-0 md:basis-auto grow md:relative">
            <button
              aria-labelledby="shop"
              class="group mx-auto flex flex-col lg:flex-row items-center lg:gap-3 md:py-1 md:px-3 md:rounded-lg hover:bg-white hover:bg-opacity-20"
              [ngClass]="textColorClasses()">
              <svg viewBox="0 0 60 60" class="h-8 w-8 block m-auto object-cover p-0.5"
                   [ngClass]="{'lg:h-10 lg:w-10': !headerService.search()}">
                <defs>
                  <style>
                    .cls-1 {
                      fill: none;
                      stroke-miterlimit: 10;
                      stroke-width: 1.6px;
                    }
                  </style>
                </defs>
                <g>
                  <g>
                    <path class="cls-1" [ngClass]="svgColorClasses()"
                          d="M11.9,9v-2c0-2.9,2.3-5.2,5.2-5.2h25.9c2.9,0,5.2,2.3,5.2,5.2v2"/>
                    <path class="cls-1" [ngClass]="svgColorClasses()"
                          d="M48.1,28.6v24.4c0,2.9-2.3,5.2-5.2,5.2h-25.9c-2.9,0-5.2-2.3-5.2-5.2v-24.6"/>
                    <polyline class="cls-1" [ngClass]="svgColorClasses()" points="42.7 48.2 17.2 48.2 17.2 32.9"/>
                  </g>
                  <line class="cls-1" [ngClass]="svgColorClasses()" x1="26.3" y1="53.4" x2="33.7" y2="53.4"/>
                </g>
                <path class="cls-1" [ngClass]="svgColorClasses()"
                      d="M38.2,17.8v2.5c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2v-2.5l-6.2-8.6H11.8l-6.2,8.6v2.5c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2v-2.5"/>
                <path class="cls-1" [ngClass]="svgColorClasses()"
                      d="M21.8,20.2h0c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2h0"/>
                <line class="cls-1" [ngClass]="svgColorClasses()" x1="5.5" y1="17.8" x2="54.5" y2="17.8"/>
                <line class="cls-1" [ngClass]="svgColorClasses()" x1="21.8" y1="17.8" x2="24" y2="9.2"/>
                <line class="cls-1" [ngClass]="svgColorClasses()" x1="38.2" y1="17.8" x2="36" y2="9.2"/>
              </svg>
              <span class="text-xs lg:text-base lg:pr-4">{{ 'shop' | translate }}</span>
            </button>
            <ul class="absolute grid max-h-[80vh] overflow-y-auto  gap-2 z-10 rounded-md bg-white py-4 px-5 ring-1
            text-base shadow-lg ring-black ring-opacity-5 bottom-14 left-4 right-4 m-auto focus:outline-none
            md:min-w-max md:-left-52 md:grid-cols-2 md:max-w-fit md:top-16 md:bottom-auto md:px-7 sm:text-sm">
              @if (headerService.type() !== 'minimal') {
                <svg class="fill-white h-3 mx-auto hidden lg:block absolute right-0 left-0 -top-3"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 381.95 150.4">
                  <path class="fill-white" d="M0,150.4C51.71,98.69,102.14,48.26,121.71,28.69C126.33,24.08,151.32,0,190.98,0
	c25.07,0,50.14,9.56,69.27,28.69c20.7,20.7,71.87,71.87,121.71,121.71"/>
                </svg>
              }
              @for (center of centerService.centers(); track center.id) {
                <li
                  class="text-100ciels-500 grow hover:text-100ciels-300 rounded select-none cursor-pointer font-normal"
                  [ngClass]="{
                  'font-semibold': selectionService.center()?.id === center.id,
                  '!bg-gray-100 hover:!bg-gray-100 cursor-default': !center.url
                  }"
                  [attr.aria-selected]="selectionService.center()?.id === center.id">
                  <button class="text-lg block truncate px-4 py-2 w-full"
                          [disabled]="!center.url"
                          (click)="navigationService.goTo(center, '/offers')"
                          (keyup.enter)="emulateClick($event)">
                    <p class="w-full text-sm text-left leading-6 font-semibold">{{ center.name }}</p>
                    <p class="w-full text-xs text-left leading-5 truncate">{{ center.city }}</p>
                  </button>
                </li>
              }
            </ul>
          </div>
          <!--Gift button which display shops-->
          <div appHover appAutoClose="always" class="basis-0 md:basis-auto grow md:relative">
            <button
              aria-labelledby="giftVouchers"
              class="group mx-auto flex flex-col lg:flex-row items-center lg:gap-3 md:py-1 md:px-3 rounded-lg hover:bg-white hover:bg-opacity-20"
              [ngClass]="textColorClasses()">
              <svg viewBox="0 0 60 60" class="h-9 w-9 lg:h-12 lg:w-12 block m-auto object-cover">
                <defs>
                  <style>.cls-1 {
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.76px;
                  }</style>
                </defs>
                <path class="cls-1 fill-none stroke-2"
                      [ngClass]="svgColorClasses()"
                      d="m24.72,30.69h-12.31c-1.46,0-2.64-1.18-2.64-2.64v-3.52c0-1.46,1.18-2.64,2.64-2.64h35.18c1.46,0,2.64,1.18,2.64,2.64v3.52c0,1.46-1.18,2.64-2.64,2.64h-12.31m0-5.28v10.55l-5.28-1.76-5.28,1.76v-14.07m22.87,13.19v12.31c0,1.46-1.18,2.64-2.64,2.64H15.05c-1.46,0-2.64-1.18-2.64-2.64v-16.71m24.62-11.05c7.01-3.93,6.07-2.98,2.75-8.55-2.18-3.65-5.76-.1-8.9,4.62m-10.55,4.07c-3.99-2.06-2.95-2.04-.24-6.17,2.21-3.36,5.94.39,9.06,4.86"/>
              </svg>
              <span class="lg:hidden text-xs">{{ 'gift' | translate }}</span>
              <span class="hidden lg:block pr-4">{{ 'giftVouchers' | translate }}</span>
            </button>
            <ul class="absolute grid max-h-[80vh] overflow-y-auto  gap-2 z-10 rounded-md bg-white py-4 px-5 ring-1
            text-base shadow-lg ring-black ring-opacity-5 bottom-14 left-4 right-4 m-auto focus:outline-none
            md:min-w-max md:-left-64 md:grid-cols-2 md:max-w-fit md:top-16 md:bottom-auto md:px-7 sm:text-sm">
              @if (headerService.type() !== 'minimal') {
                <svg class="fill-white h-3 mx-auto hidden lg:block absolute right-0 left-0 -top-3"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 381.95 150.4">
                  <path class="fill-white" d="M0,150.4C51.71,98.69,102.14,48.26,121.71,28.69C126.33,24.08,151.32,0,190.98,0
	c25.07,0,50.14,9.56,69.27,28.69c20.7,20.7,71.87,71.87,121.71,121.71"/>
                </svg>
              }
              @for (center of centerService.centers(); track center.id) {
                <li
                  class="text-100ciels-500 grow hover:text-100ciels-300 rounded select-none cursor-pointer font-normal"
                  [ngClass]="{
                  'font-semibold': selectionService.center()?.id === center.id,
                  '!bg-gray-100 hover:!bg-gray-100 cursor-default': !center.url
                  }"
                  [attr.aria-selected]="selectionService.center()?.id === center.id">
                  <button class="text-lg block truncate px-4 py-2 w-full"
                          [disabled]="!center.url"
                          (click)="navigationService.goTo(center, '/offers?gift=true')"
                          (keyup.enter)="emulateClick($event)">
                    <p class="w-full text-sm text-left leading-6 font-semibold">{{ center.name }}</p>
                    <p class="w-full text-xs text-left leading-5 truncate">{{ center.city }}</p>
                  </button>
                </li>
              }
            </ul>
          </div>
        }
        <!--Checkout block-->
        @if (siteService.center()) {
          <div class="self-stretch !border-l my-4 lg:mx-2 md:mx-2 hidden md:block"
               [ngClass]="!giftMode()?(headerService.type() === 'minimal' ? 'border-100ciels-300' : 'border-white')
               :(headerService.type() === 'minimal' ? 'border-100ciels-300' : 'border-white')">
          </div>
          <a routerLink="/checkout"
             [attr.aria-label]="'goToCart' | translate"
             class="basis-0 md:basis-auto grow relative group flex items-center justify-center gap-3 md:py-2 md:px-2 hover:bg-white hover:bg-opacity-20"
             [ngClass]="textColorClasses()">
            <div class="flex flex-col items-center">
              <svg viewBox="0 0 24 24" class="w-9 h-9 block m-auto object-cover"
                   [ngClass]="{'lg:w-10 lg:h-10': !headerService.search()}">
                <path class="fill-none"
                      stroke-width="0.75"
                      [ngClass]="svgColorClasses()"
                      d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"/>
              </svg>
              <span class="text-xs"
                    [ngClass]="{'lg:hidden': !headerService.search()}">{{ 'checkout' | translate }}</span>
            </div>
            @if (cartService.numberOfItems(); as cartLength) {
              <span
                class="absolute top-0 bottom-0 left-0 right-0 mt-3 md:mt-5  m-auto font-semibold text-center"
                [ngClass]="{'lg:static lg:mt-2': !headerService.search()}">{{ cartLength }}</span>
            }
          </a>
        }
        <!--Auth block-->
        @if (authService.user()?.authCompleted) {
          <div appAutoClose="outside" class="basis-0 md:basis-auto grow relative">
            <button [attr.aria-label]="'auth' | translate" class="group mx-auto
                  flex items-center gap-3 md:py-2 md:px-3">
              @if (authService.user()?.photoURL; as url) {
                <img class="w-12 h-12 rounded-full ring-2 ring-white shadow-md object-cover" width="48" height="48"
                     [ngSrc]="url" alt="Bordered avatar">
              } @else {
                <div
                  class="font-bold text-100ciels-500 rounded-full bg-white flex items-center
                  justify-center text-xl h-12 w-12 ring-2 ring-100ciels-300 shadow-md">
                  {{ authService.user()?.firstName?.substring(0, 1) ?? '' }}
                </div>
              }
            </button>
            <ul class="absolute min-w-max right-4 bottom-16 md:-right-20 md:top-16">
              <app-profile-card/>
            </ul>
          </div>
        } @else {
          <button [attr.aria-label]="'auth' | translate"
                  class="basis-0 md:basis-auto grow relative group mx-auto flex flex-col gap-2 items-center md:py-2 md:px-3
                  hover:bg-white hover:bg-opacity-20 md:rounded-lg
                  lg:hover:bg-transparent"
                  [disabled]="authService.initAuthProgress()"
                  (click)="drawerService.open({component: SignInComponent, inputs: { inDrawer: true }, style: 'transparent'})"
                  [ngClass]="textColorClasses()">
            <svg viewBox="0 0 40 40" class="h-7 w-7 block m-auto object-cover lg:hidden">
              <path
                class="fill-none stroke-2"
                [ngClass]="svgColorClasses()"
                d="M3.75,38.75c0-10,2.5-17.5,12.84-17.5h6.82c10.34,0,12.84,7.5,12.84,17.5 M30,11.25c0,5.52-4.48,10-10,10s-10-4.48-10-10 s4.48-10,10-10S30,5.73,30,11.25z"/>
            </svg>
            <span class="text-xs lg:hidden">{{ 'auth' | translate }}</span>
            <span class="hidden lg:block" appHoverGradient>
                @if (authService.initAuthProgress()) {
                  <svg class="animate-spin h-6 w-6 mx-8 text-white" xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                } @else {
                  {{ 'auth' | translate }}
                }
            </span>
          </button>
        }
      </div>
    </nav>
    <!--Reservation Card-->
    @if (headerService.type() === 'full') {
      <div class="layout pt-5">
        <!--Card-->
        <div
          class="relative block w-full md:max-w-lg rounded-lg bg-white bg-opacity-40 backdrop-blur-2xl px-8 lg:px-14 py-8 lg:py-14
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
          <app-lang-selector class="absolute top-4 right-4 z-20"/>
          @if (siteService.center(); as center) {
            <!--Subtitle-->
            <p class="text-100ciels-300 font-semibold text-xl mb-6">{{ 'header.welcome' | translate }}</p>
            <!--Center title -->
            <h1 class="font-bold text-3xl md:text-5xl leading-tight text-100ciels-500">
              {{ center.name }}
            </h1>
          } @else {
            <!--Subtitle-->
            <p class="text-100ciels-300 font-semibold text-xl mb-6">{{ 'header.subtitle' | translate }}</p>
            <!--Center title -->
            <h1 class="font-bold text-3xl md:text-4xl leading-tight text-100ciels-500">
              {{ 'header.title' | translate }}
            </h1>
          }
          <!-- Description -->
          <p [innerHtml]="'header.description' | translate" class="my-4 text-100ciels-500 font-semibold"></p>
          <!-- Center Choice -->
          <div appAutoClose="always" class="relative mt-2">
            <button type="button"
                    aria-labelledby="centerChoice"
                    class="bg-gray-100 bg-opacity-50 py-5 px-4 pr-10 md:px-7 hover:bg-gray-200 duration-200 cursor-pointer relative w-full rounded-md text-left text-gray-900 focus:outline-none">
              <div class="flex items-center">
                <div class="w-12">
                  <img class="h-9 w-9 flex-shrink-0 m-auto"
                       ngSrc="assets/icons/gray/location.svg" width="60" height="60"
                       alt="{{'100cielsLocation' | translate}}">
                </div>
                <div
                  class="truncate text-100ciels-500 font-semibold text-lg ml-4 md:ml-6">
                  <p id="centerChoice"
                     class="font-semibold truncate text-xs">{{ '100cielsCenters' | translate }}</p>
                  {{ selectionService.center()?.name }}
                </div>
              </div>
              <div class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-6">
                <img ngSrc="assets/icons/gray/down.svg" width="60" height="60" alt="" class="h-4 w-4">
              </div>
            </button>
            <ul
              class="hidden absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base
              shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              @for (center of centerService.centers(); track center.id) {
                <li [attr.aria-selected]="selectionService.center()?.id === center.id">
                  <div class="flex items-center py-4 px-3 md:px-5 cursor-pointer text-gray-900 hover:bg-gray-100
                    relative select-none" (click)="selectCenter(center)" role="button"
                       tabindex="0" onKeyUp (keyup.enter)="emulateClick($event)">
                    <span [ngClass]="selectionService.center()?.id === center.id ? 'font-semibold' : 'font-normal'"
                          class="text-lg text-100ciels-500 ml-3 block truncate">
                      {{ center.name }} - {{ center.city }}
                    </span>
                  </div>
                </li>
              }
            </ul>
          </div>
          <!-- Universe Choice -->
          <div appAutoClose="always" class="relative mt-2">
            <button type="button"
                    aria-labelledby="universeChoice"
                    class="bg-opacity-50 bg-gray-100 py-4 px-4 md:px-7 hover:bg-gray-200 duration-200 cursor-pointer relative w-full rounded-md text-left text-gray-900 focus:outline-none">
              <div class="flex items-center">
                <div class="w-12">
                  <img [ngSrc]="'assets/icons/gray/'+ (selectionService.universe()?.icon ?? 'spa.svg')" alt=""
                       width="36"
                       height="36"
                       class="h-9 w-9 flex-shrink-0 m-auto">
                </div>
                <div
                  class="truncate text-100ciels-500 font-semibold text-lg ml-4 md:ml-6">
                  <p id="universeChoice"
                     class="font-semibold truncate text-xs">{{ '100cielsUniverseChoice' | translate }}</p>
                  @if (selectionService.universe(); as universe) {
                    {{ 'universe/' + universe.id + '/name' | translate }}
                  } @else {
                    {{ 'seeAll' | translate }}
                  }
                </div>
              </div>
              <div class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-6">
                <img ngSrc="assets/icons/gray/down.svg" width="60" height="60"
                     alt="{{'the100cielsUniverse' | translate}}"
                     class="h-4 w-4">
              </div>
            </button>
            <ul class="hidden absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base
              shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <li class="text-gray-900 hover:bg-gray-100 relative select-none py-4 px-3 md:px-5 cursor-pointer"
                  [attr.aria-selected]="selectionService.universe() === null">
                <div class="flex items-center" (click)="selectUniverse(null)" tabindex="0" role="button"
                     (keyup.enter)="emulateClick($event)">
                    <span class="text-lg text-100ciels-500 ml-3 block truncate"
                          [ngClass]="selectionService.universe() === null ? 'font-semibold' : 'font-normal'">
                      {{ 'seeAll' | translate }}
                    </span>
                </div>
              </li>
              @for (universe of universeService.universes(); track universe.id) {
                <li [attr.aria-selected]="selectionService.universe()?.id === universe.id">
                  <div class="flex items-center text-gray-900 hover:bg-gray-100 relative select-none py-4 px-5
                      md:px-7 cursor-pointer" (click)="selectUniverse(universe)" role="button"
                       tabindex="0" onKeyUp (keyup.enter)="emulateClick($event)">
                    <img class="h-7 w-7 flex-shrink-0"
                         [ngSrc]="'assets/icons/gray/'+universe.icon" width="28" height="28"
                         alt="universe icon">
                    <span class="text-lg text-100ciels-500 ml-3 block truncate"
                          [ngClass]="selectionService.universe()?.id === universe.id ? 'font-semibold' : 'font-normal'">
                         {{ 'universe/' + universe.id + '/name' | translate }}
                      </span>
                  </div>
                </li>
              }
            </ul>
          </div>
          <!-- Product Choice -->
          <div appAutoClose="always" class="relative mt-2">
            <button type="button"
                    aria-labelledby="productChoice"
                    class="bg-opacity-50 bg-gray-100 py-4 px-4 md:px-7 hover:bg-gray-200 duration-200 cursor-pointer relative w-full
                      rounded-md text-left text-gray-900 focus:outline-none">
              <span class="flex items-center">
                <div class="w-12">
                <img ngSrc="assets/icons/gray/cards.svg" alt="{{'100cielsProduct' | translate}}" width="50" height="50"
                     class="h-12 w-12 flex-shrink-0 text-center">
                  </div>
                <span
                  class="truncate text-100ciels-500 font-semibold text-lg ml-4 md:ml-6">
                  <p id="productChoice" class="font-semibold truncate text-xs">{{ 'yourPass' | translate }}</p>
                  @if (selectionService.product(); as product) {
                    {{ 'product/' + product.id + '/name' | translate }}
                  } @else {
                    {{ 'seeAll' | translate }}
                  }
                </span>
              </span>
              <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-6">
                 <img ngSrc="assets/icons/gray/down.svg" width="100" height="100"
                      alt="{{'100cielsProduct' | translate}}" class="h-4 w-4">
                </span>
            </button>
            <ul class="hidden absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base
                shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <li class="text-gray-900 hover:bg-gray-100 relative select-none py-4 px-3 md:px-5 cursor-pointer"
                  [attr.aria-selected]="selectionService.product() === null">
                <div class="flex items-center" (click)="selectProduct(null)" tabindex="0" role="button"
                     (keyup.enter)="emulateClick($event)">
                    <span class="text-lg text-100ciels-500 ml-3 block truncate"
                          [ngClass]="selectionService.product() === null ? 'font-semibold' : 'font-normal'">
                      {{ 'seeAll' | translate }}
                    </span>
                </div>
              </li>
              @for (product of productService.quickPurchases(); track product.id) {
                <li class="text-gray-900 hover:bg-gray-100 relative select-none py-4 px-3 md:px-5 cursor-pointer"
                    [attr.aria-selected]="selectionService.product()?.id === product.id">
                  <div class="flex items-center" (click)="selectProduct(product)" tabindex="0" role="button"
                       (keyup.enter)="emulateClick($event)">
                    <span class="text-lg text-100ciels-500 ml-3 block truncate"
                          [ngClass]="selectionService.product()?.id === product.id ? 'font-semibold' : 'font-normal'">
                      {{ 'product/' + product.id + '/name' | translate }}
                    </span>
                  </div>
                </li>
              }
            </ul>
          </div>
          <!--Big Button-->
          <button appHoverGradient class="big-btn" (click)="quickPurchase()"
                  [disabled]="!selectionService.center()?.url">
            {{ 'continue' | translate }}
          </button>
        </div>
      </div>
    } @else if (headerService.type() === 'plain') {
      <div class="min-h-80"></div>
    }
  </div>
</header>


